'use client';

import useMetricsCubeQuery from 'app/hooks/useMetricsCubeQuery';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import YtdVolumeWidget from './YtdVolumeWidget';

const currentYear = dayjs().year();
const lastYear = dayjs().subtract(1, 'year').year();
const yearBeforeLast = dayjs().subtract(2, 'year').year();

export default function YtdTrends() {
  const t = useTranslations();

  const { data: ytmData, isLoading: isLoadingYtmData } = useMetricsCubeQuery({
    metric: 'FOOTPRINT',
    timeFrame: 'YTM',
  });

  const lastYtmData = ytmData?.find(
    (d) => d.periodStartDate.getFullYear() === currentYear,
  );
  const previousYtmData = ytmData?.find(
    (d) => d.periodStartDate.getFullYear() === lastYear,
  );

  const canShowYtmData = !!lastYtmData;

  const { data: yearData, isLoading: isLoadingYearData } = useMetricsCubeQuery(
    // Only fetch when we don't have YTM data
    !isLoadingYtmData && !canShowYtmData
      ? {
          metric: 'FOOTPRINT',
          timeFrame: 'YEAR',
        }
      : null,
  );

  const lastYearData = yearData?.find(
    (d) => d.periodStartDate.getFullYear() === lastYear,
  );
  const previousYearData = yearData?.find(
    (d) => d.periodStartDate.getFullYear() === yearBeforeLast,
  );
  const canShowYearData = !!lastYearData;

  const dateIndicatorString = useMemo(() => {
    if (!canShowYtmData && !canShowYearData) {
      // No data available
      return t('YtdTrends.dateRange.noData');
    }

    if (canShowYtmData) {
      if (previousYtmData) {
        return t('YtdTrends.dateRange.ytmCompare', {
          lastStartMonth: dayjs(lastYtmData.periodStartDate).format('MMMM'),
          lastEndMonth: dayjs(lastYtmData.periodEndDate).format('MMM'),
          lastYear: dayjs(lastYtmData.periodEndDate).format('YYYY'),
          previousStartMonth: dayjs(previousYtmData.periodStartDate).format(
            'MMM',
          ),
          previousEndMonth: dayjs(previousYtmData.periodEndDate).format('MMM'),
          previousYear: dayjs(previousYtmData.periodEndDate).format('YYYY'),
        });
      }

      return t('YtdTrends.dateRange.ytm', {
        lastStartMonth: dayjs(lastYtmData.periodStartDate).format('MMMM'),
        lastEndMonth: dayjs(lastYtmData.periodEndDate).format('MMM'),
        lastYear: dayjs(lastYtmData.periodEndDate).format('YYYY'),
      });
    }

    if (canShowYearData) {
      if (previousYearData) {
        return t('YtdTrends.dateRange.yearCompare', {
          year: dayjs(lastYearData.periodEndDate).format('YYYY'),
          previousYear: dayjs(previousYearData.periodEndDate).format('YYYY'),
        });
      }

      return t('YtdTrends.dateRange.year', {
        year: dayjs(lastYearData.periodEndDate).format('YYYY'),
      });
    }
  }, [
    t,
    canShowYtmData,
    canShowYearData,
    lastYtmData,
    previousYtmData,
    lastYearData,
    previousYearData,
  ]);

  const isLoading = isLoadingYtmData || isLoadingYearData;

  const shownPeriod = canShowYtmData
    ? lastYtmData?.period
    : lastYearData?.period;
  const previousPeriod = canShowYtmData
    ? previousYtmData?.period
    : previousYearData?.period;

  return (
    <section className="w-full">
      <h2 className="text-carbon-800 text-base font-medium">
        {t('YtdTrends.keyFigures')}{' '}
      </h2>
      <h3 className="text-carbon-500 text-xs font-medium mb-3">
        {dateIndicatorString}
      </h3>

      <div className="w-full flex flex-row flex-wrap gap-2 [align-items:stretch] [justify-content:stretch]">
        <YtdVolumeWidget
          metric="FOOTPRINT"
          isLoading={isLoading}
          period={shownPeriod}
          compareToPeriod={previousPeriod}
        />
        <YtdVolumeWidget
          metric="composite_FOOTPRINT_PER_UNIT"
          isLoading={isLoading}
          period={shownPeriod}
          compareToPeriod={previousPeriod}
        />
        <YtdVolumeWidget
          metric="UNITS_MEASURED"
          isLoading={isLoading}
          period={shownPeriod}
          compareToPeriod={previousPeriod}
        />
      </div>
    </section>
  );
}
