'use client';

import Title from 'app/components/Title';
import useEndpoint from 'app/lib/useEndpoint';
import { useTranslations } from 'next-intl';

export default function AccountGreet() {
  const { data: metadata } = useEndpoint('/metadata', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const t = useTranslations();

  return (
    <div>
      <span className="text-carbon-500 text-sm font-medium">
        {t('AccountGreet.welcome')}
      </span>
      <Title title={metadata?.accountName || 'Home'} />
    </div>
  );
}
