import type { Route } from 'next';
import Link from 'next/link';

import Icon, { type LocalIconKey } from '@carbonfact/ui-components/src/Icon';

type DisableProps =
  | {
      disabled: true;
      disableParams?: {
        text?: string;
        icon?: LocalIconKey;
      };
    }
  | {
      disabled?: false;
      disableParams?: undefined;
    };

export type CardProps = {
  title: string;
  description: string;
  icon: {
    name?: LocalIconKey;
    text?: string;
    type?: 'line' | 'solid';
  };
  link?: {
    href: URL | Route;
    label: string;
  };
  footer?: React.ReactNode;
} & DisableProps;

export const Default = ({
  disabled = false,
  disableParams,
  title,
  footer,
  description,
  icon,
  link,
}: CardProps) => {
  return (
    <div className="rounded-md border border-gray-200 min-h-[200px] bg-white p-4 hover:border-gray-300 flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center justify-center rounded-md bg-carbon-100 leading-none text-carbon-700 p-1">
          <Icon
            height={24}
            width={24}
            icon={
              icon.name
                ? {
                    name: icon.name,
                    type: icon.type || 'solid',
                    source: 'local',
                  }
                : { text: icon.text || '' }
            }
          />
        </div>
        {disabled && (
          <div className="flex items-center whitespace-nowrap rounded-full bg-gray-200 px-3 py-1.5 text-sm font-medium text-gray-700">
            {disableParams?.icon && (
              <Icon
                height={16}
                width={16}
                icon={{
                  name: disableParams?.icon,
                  type: 'solid',
                  source: 'local',
                }}
              />
            )}
            {disableParams?.text || 'Locked'}
          </div>
        )}
      </div>
      <div className="grow">
        <div
          className={`mb-2 font-medium ${
            disabled ? 'text-gray-500' : 'text-gray-800'
          }`}
        >
          {title}
        </div>
        <div
          className={`text-sm ${disabled ? 'text-gray-400' : 'text-gray-600'}`}
        >
          {description}
        </div>
      </div>
      <div>
        {!disabled && link && (
          <Link
            role="link"
            className="text-gray-700 bg-white border-[1.5px] border-gray-100 items-center rounded-md px-3 py-1.5 text-sm shadow font-medium whitespace-nowrap"
            href={link.href}
          >
            {link.label}
          </Link>
        )}
        {footer || ''}
      </div>
    </div>
  );
};
