'use client';
import { Tag } from '@carbonfact/ui-components/src/Tag';
import { Card } from 'app/components/Card';
import useMetricsCubeFormattingUtils from 'app/hooks/useMetricsCubeFormattingUtils';
import useMetricsCubeQuery from 'app/hooks/useMetricsCubeQuery';
import { formatPercentDifference } from 'app/lib/formatNumber';
import { useTranslations } from 'next-intl';

interface YtdVolumeWidgetProps {
  metric: string;
  isLoading: boolean;
  period?: string;
  compareToPeriod?: string;
}

export default function YtdVolumeWidget({
  metric,
  isLoading: propsIsLoading,
  period,
  compareToPeriod,
}: YtdVolumeWidgetProps) {
  const t = useTranslations();

  const cubeFmt = useMetricsCubeFormattingUtils({ metric, timeFrame: 'YTM' });

  const { data: lastPeriodData, isLoading: isLoadingLastPeriodData } =
    useMetricsCubeQuery(
      period
        ? {
            metric,
            timePeriod: period,
          }
        : null,
    );

  const { data: previousPeriodData, isLoading: isLoadingPreviousPeriodData } =
    useMetricsCubeQuery(
      compareToPeriod
        ? {
            metric,
            timePeriod: compareToPeriod,
          }
        : null,
    );

  // Compare with previous period

  const periodOverPeriodValue =
    lastPeriodData?.[0] && previousPeriodData?.[0]
      ? lastPeriodData[0].metricValue / previousPeriodData[0].metricValue
      : null;

  const percentDifferenceFromLastTimePeriod = periodOverPeriodValue
    ? periodOverPeriodValue - 1
    : null;
  const formattedPercentDifferenceFromLastTimePeriod = formatPercentDifference(
    percentDifferenceFromLastTimePeriod ?? 0,
  );

  return (
    <Card.Value
      className="min-w-[250px] flex-1"
      title={cubeFmt.formatMetricsCubeString(metric)}
      value={
        lastPeriodData?.[0]
          ? cubeFmt.formatMetricValue(
              cubeFmt.getMetricValue(lastPeriodData?.[0]),
            )
          : 'N/A'
      }
      suffix={
        <>
          {cubeFmt.metricSuffix === 'metric' ? '' : cubeFmt.metricSuffix}
          {'  '}
          {previousPeriodData?.[0] && (
            <Tag
              className={`text-gray-700 bg-gray-100 ${
                metric !== 'UNITS_MEASURED' &&
                getEvolutionTagColor(
                  Number(percentDifferenceFromLastTimePeriod),
                )
              } inline-flex w-fit leading-[inherit]`}
            >
              {isDifferenceSignificant(
                percentDifferenceFromLastTimePeriod ?? 0,
              ) ? (
                <>{formattedPercentDifferenceFromLastTimePeriod}</>
              ) : (
                <>{t('YtdVolumeWidget.stable')}</>
              )}
            </Tag>
          )}
        </>
      }
      loading={
        propsIsLoading || isLoadingLastPeriodData || isLoadingPreviousPeriodData
      }
    />
  );
}

function isDifferenceSignificant(percentDifference: number): boolean {
  return Math.abs(percentDifference ?? 0) > 0.05;
}

function getEvolutionTagColor(percentDifferenceFromLastYear: number): string {
  if (!isDifferenceSignificant(percentDifferenceFromLastYear)) {
    // Evolution is tiny enough to get rounded out, show as stable.
    return 'bg-gray-100';
  }

  if (percentDifferenceFromLastYear <= 0) {
    // Less emissions is better!
    return 'bg-green-100';
  }
  return 'bg-red-100';
}
