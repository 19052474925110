import { ReleaseMetric } from '@carbonfact/shared/src/types/platform/releases';
import formatNumber, {
  formatCount,
  formatPercent,
  formatRelativeUncertainty,
} from 'app/lib/formatNumber';

export function displayReleaseMetricValue(
  metric: ReleaseMetric,
  value: number,
): string {
  switch (metric) {
    case ReleaseMetric.PRODUCT_FOOTPRINT:
    case ReleaseMetric.UNITS:
      // for the footprint metrics, the "kgCO2e" unit is already displayed in the metric name header
      return formatCount(value);
    case ReleaseMetric.TOTAL_FOOTPRINT:
      // total footprint is in tons
      return formatCount(value / 1000);
    case ReleaseMetric.RELATIVE_UNCERTAINTY:
      // the value is the width of the interval
      return formatRelativeUncertainty(value / 2);
    case ReleaseMetric.PRODUCT_COUNT:
      // don't abbreviate count of SKUs
      return formatNumber(value);
    default:
      return formatPercent(value);
  }
}
